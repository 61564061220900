var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box" },
    [
      _c("UserHeader"),
      _c("div", { staticClass: "out-box" }, [
        _c("div", { staticClass: "in-box" }, [
          _c("div", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.srmAccount,
                  expression: "srmAccount",
                },
              ],
              attrs: { type: "text", placeholder: "请输入供应商帐户" },
              domProps: { value: _vm.srmAccount },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.srmAccount = $event.target.value
                },
              },
            }),
            _vm.srmAccount
              ? _c("i", {
                  staticClass: "clearBtn",
                  on: {
                    click: function ($event) {
                      _vm.srmAccount = ""
                    },
                  },
                })
              : _vm._e(),
          ]),
          _c("div", [
            _vm.inputType === "checkbox"
              ? _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.srmPassword,
                      expression: "srmPassword",
                    },
                  ],
                  attrs: { placeholder: "请输入密码", type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.srmPassword)
                      ? _vm._i(_vm.srmPassword, null) > -1
                      : _vm.srmPassword,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.srmPassword,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.srmPassword = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.srmPassword = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.srmPassword = $$c
                      }
                    },
                  },
                })
              : _vm.inputType === "radio"
              ? _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.srmPassword,
                      expression: "srmPassword",
                    },
                  ],
                  attrs: { placeholder: "请输入密码", type: "radio" },
                  domProps: { checked: _vm._q(_vm.srmPassword, null) },
                  on: {
                    change: function ($event) {
                      _vm.srmPassword = null
                    },
                  },
                })
              : _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.srmPassword,
                      expression: "srmPassword",
                    },
                  ],
                  attrs: { placeholder: "请输入密码", type: _vm.inputType },
                  domProps: { value: _vm.srmPassword },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.srmPassword = $event.target.value
                    },
                  },
                }),
            _vm.srmPassword
              ? _c("i", {
                  staticClass: "clearBtn clearSrmPassword",
                  on: {
                    click: function ($event) {
                      _vm.srmPassword = ""
                    },
                  },
                })
              : _vm._e(),
            _c("label", { on: { click: _vm.toggleEyes } }, [
              _c("img", { attrs: { src: _vm.iconPath, alt: "", id: "eyes" } }),
            ]),
          ]),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "btn-box" },
        [
          _c(
            "van-button",
            { attrs: { type: "primary" }, on: { click: _vm.bindUser } },
            [_vm._v("绑定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }