import BindUser from "@/page/user/BindUser.vue";
import MyUser from "@/page/user/MyUser.vue";

import Vue from 'vue'
import VueRouter from 'vue-router'
import { Field } from 'vant';

Vue.use(Field);
Vue.use(VueRouter)

export default new VueRouter({
    // mode: 'history',
    // base: process.env.BASE_URL,
    // scrollBehavior: () => ({ y: 0 }),
    routes:[
        {
            path: '/bindUser',
            component: BindUser,
            meta: {
                title:"绑定用户"
            }
        }, {
            path: '/myUser',
            component: MyUser,
            meta: {
                title:"我的用户"
            }
        }, {
            path: '/',
            component: MyUser,
            meta: {
                title:"我的用户"
            }
        }
    ]
})