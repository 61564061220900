<template>

  <div class=".title">
    <h2 align="center">{{title}}</h2>
  </div>
</template>

<script>
export default {
  data() {
    return{
      title: "供应商寻源协同"
    }
  }
}
</script>

<style scoped>
  h1{
    margin-top: 30px;
    font-size: 30px;
  }

</style>