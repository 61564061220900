// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/close.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\nlabel[data-v-a82dfcb6] {\r\n  position: absolute;\r\n  z-index: 2;\r\n  left: 300px;\r\n  top: 50%;\r\n  margin-top: 5px;\n}\n#eyes[data-v-a82dfcb6] {\r\n  width: 28px;\r\n  height: 28px;\n}\n.out-box[data-v-a82dfcb6] {\r\n  margin: 50px auto;\r\n  width: 350px;\r\n  background-color: #ffffff;\r\n  text-align: center;\r\n  border-radius: 5px;\n}\n.in-box[data-v-a82dfcb6] {\r\n  padding: 23px 10px 40px 10px;\r\n  position: relative;\n}\n.in-box input[data-v-a82dfcb6] {\r\n  border: none;\r\n  border-bottom: 1px solid #dedede;\r\n  width: 320px;\r\n  height: 48px;\r\n  line-height: 48px;\n}\n.clearBtn[data-v-a82dfcb6] {\r\n  position: absolute;\r\n  z-index: 2;\r\n  left: 320px;\r\n  top: 50%;\r\n  margin-top: -40px;\r\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\r\n  background-size: 12px 12px;\r\n  width: 12px;\r\n  height: 12px;\n}\n.clearSrmPassword[data-v-a82dfcb6] {\r\n  left: 280px;\r\n  margin-top: 14px;\n}\n.btn-box[data-v-a82dfcb6] {\r\n  margin-top: 20px;\r\n  text-align: center;\n}\n.btn-box button[data-v-a82dfcb6] {\r\n  background-color: #45bdaa;\r\n  border:1px solid #45bdaa;\r\n  width: 350px;\r\n  font-size: 18px;\r\n  height: 44px;\r\n  border-radius: 22px;\r\n  outline-color: #eee;\n}\n[data-v-a82dfcb6]::-webkit-input-placeholder { /* WebKit browsers */\r\n  color: #888;\r\n  font-size: 18px;\n}\n[data-v-a82dfcb6]:-moz-placeholder { /* Mozilla Firefox 4 to 18 */\r\n  color: #888;\r\n  font-size: 18px;\n}\n[data-v-a82dfcb6]::-moz-placeholder { /* Mozilla Firefox 19+ */\r\n  color: #888;\r\n  font-size: 18px;\n}\n[data-v-a82dfcb6]:-ms-input-placeholder { /* Internet Explorer 10+ */\r\n  color: #888;\r\n  font-size: 18px;\n}\r\n", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
