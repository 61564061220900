<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  components:{

  }
}
</script>
<style>
body{
  background-color: #f7f8fa;
}
a{
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-user-select: none;
  -moz-user-focus: ignore;
  -moz-user-select: none;
}
</style>