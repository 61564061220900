import {getAction, postAction} from "@/api/request";

const getBindUserApi = (search, params = {}) => getAction("getBindUser" + search, params);
const bindUserApi = (params = {}) => postAction("bindUser", params);
const unBindUserApi = (params = {}) => postAction("unBindUser", params);

export {
    getBindUserApi,
    bindUserApi,
    unBindUserApi
}