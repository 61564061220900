var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("UserHeader"),
      _c("br"),
      _c("p", { staticStyle: { "margin-left": "16px", "font-size": "12px" } }, [
        _vm._v("已绑定供应商"),
      ]),
      _vm._l(_vm.srmUserList, function (user) {
        return _c(
          "div",
          { key: user.srmAccount },
          [
            _c(
              "van-cell-group",
              { staticClass: "item-group", attrs: { inset: "" } },
              [
                _c("van-cell", {
                  attrs: { title: "供应商帐号", value: user.srmAccount },
                }),
                _c("van-cell", {
                  attrs: { title: "供应商名称", value: user.srmName },
                }),
                _c("van-cell", [
                  _c(
                    "a",
                    {
                      staticStyle: { color: "#aa00aa", "margin-right": "0px" },
                      attrs: { href: "" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.unbindUser(user.srmAccount)
                        },
                      },
                    },
                    [_vm._v("解除绑定")]
                  ),
                ]),
              ],
              1
            ),
          ],
          1
        )
      }),
      _c("div", [
        _c(
          "a",
          {
            staticStyle: {
              "margin-right": "32px",
              "font-size": "18px",
              color: "#45bdaa",
              "padding-top": "10px",
            },
            attrs: { href: "" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.toBindUser.apply(null, arguments)
              },
            },
          },
          [_vm._v("新增绑定")]
        ),
      ]),
      _c(
        "div",
        { staticClass: "loading-div" },
        [
          _c("van-loading", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.pageLoading,
                expression: "pageLoading",
              },
            ],
            staticClass: "loading-bg",
            attrs: {
              align: "center",
              type: "spinner",
              color: "#1989fa",
              size: "24px",
            },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }