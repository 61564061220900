import App from './App.vue'
import router from './router'
import Vue from 'vue';
import {Cell, CellGroup, Button, Dialog, Toast, Loading} from 'vant';

Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Button);
Vue.use(Toast);
Vue.use(Dialog);
Vue.use(Loading)

Vue.config.productionTip = false
router.beforeEach((to, from, next) => {
    // 路由发生变化修改页面title
    if (to.meta.title) {
        document.title = to.meta.title;
    }
    next();
});
new Vue({
    render: h => h(App),
    router
}).$mount('#app')


